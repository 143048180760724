import React from "react";
import { useManagersGainsRequest } from "../../Hooks/manager/useManager.query";
import { Button, Divider, Flex, List, Table, Typography } from "antd";
import { NavLink } from "react-router-dom";

const columns = [
  {
    title: "Gerente",
    dataIndex: "manager_name",
    key: "manager_name"
  },
  {
    title: "Valor aportes",
    dataIndex: "income",
    key: "income"
  }
];
const ManagerGain = () => {
  const { data, isLoading, isError, error } = useManagersGainsRequest();
  console.log(data);

  // const managers = data;
  // const dataManagers = data?.map((manager) => {
  //   const des = manager?.description;
  //   let listaOrdenada = [];
  //   if (des) {
  //     const arrayDes = JSON.parse(des);
  //     listaOrdenada = arrayDes.map((item, index) => (
  //       <List
  //         key={index} // É importante fornecer uma chave única para cada elemento da lista
  //         dataSource={[item]} // Envolver item em um array para usar no dataSource
  //         renderItem={(itens) => <List.Item> - {itens}</List.Item>}
  //       />
  //     ));
  //   }

  //   return {
  //     key: manager?.id,
  //     user_id: manager?.user_id,
  //     amount: manager?.amount,
  //     month: manager?.month,
  //     description: listaOrdenada
  //   };
  // });
  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        {/* <Button className="" onClick={handleEntryGainsManager}>
          + Novo lançamento
        </Button> */}
      </Flex>
      <Divider />

      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <p>{"record.income"}</p>
        }}
        dataSource={data}
        size="small"
      />

      {/* <Table
        columns={columns}
        className="ant-border-space"
        bordered
        expandable={{
          expandedRowRender: (record) => <p>{"record.income"}</p>
          // rowExpandable: (record) => record.name !== "Not Expandable"
        }}
        dataSource={data}
      /> */}
    </div>
  );
};

export default ManagerGain;
