import {
  Badge,
  Button,
  Descriptions,
  Divider,
  Flex,
  Form,
  List,
  Modal,
  notification,
  Typography
} from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import {
  useManagerFetchRequest,
  useManagersRequest
} from "../../Hooks/manager/useManager.query";
import { EyeFilled } from "@ant-design/icons";
import { useOfficeFetchRequest } from "../../Hooks/offices/offices.query";
import { Select } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useUpdateManager } from "../../Hooks/user/useUser.query";
import { GoArrowSwitch } from "react-icons/go";
import { PageLoading } from "../../components/Loading";
import SkeletonLoading from "../../components/Loading/skeletonLoading";

const { Text } = Typography;

const ManagerAccount = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const {
    data: dataInvestor,
    isLoading: loadingInvestor,
    isFetching
  } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const { data: dataManager, isFetching: fetchingManager } = useManagerFetchRequest(
    investor?.manager?.user_id
  );
  const manager = dataManager?.data;

  const { data: dataOffice } = useOfficeFetchRequest(manager?.office);
  const office = dataOffice?.data;

  const { data: dataManagers } = useManagersRequest();

  const items = [
    {
      key: Math.random(10),
      label: `Nome`,
      children: manager?.name
    },
    {
      key: Math.random(10),
      label: `Email`,
      children: manager?.email
    },
    {
      key: Math.random(10),
      label: `Telefone`,
      children: manager?.phone
    },
    {
      key: Math.random(10),
      label: `Whatsapp`,
      children: manager?.whatsapp
    },
    {
      key: Math.random(10),
      label: `Observação`,
      children: manager?.observation
    },
    {
      key: Math.random(10),
      label: `Escritorio`,
      children: (
        <>
          <Descriptions
            column={1}
            items={[
              {
                children: office?.name
              },
              {
                children: office?.phone
              },
              {
                children: office?.whatsapp
              },
              {
                children: (
                  <>
                    {office?.officeAddress?.street}
                    <br />
                    {office?.officeAddress?.neighborhood}
                    <br />
                    {office?.officeAddress?.complement}
                    <br />
                    {office?.officeAddress?.city}
                    <br />
                    {office?.officeAddress?.state}
                    <br />
                    {office?.officeAddress?.postal_code}
                  </>
                )
              }
            ]}
          />
        </>
      )
    }
  ];

  const { isLoading: sendindManager, mutateAsync } = useMutation(
    useUpdateManager,
    {
      onSuccess: (data) => {
        if (data.status !== 200) {
          api.error({
            message: "Erro ao alterar gerente",
            description: `ERRO: ${data.message}`,
            duration: 10
          });
        }
        api.success({
          message: "Gerente alterado com sucesso!",
          description: "Gerente alterado com sucesso!",
          duration: 10
        });
        queryClient.invalidateQueries("manager");
      }
    }
  );

  const onFinish = (formValue) => {
    const data = {
      user_id: id,
      ...formValue
    };
    mutateAsync(data);
    Modal.destroyAll();
  };

  const handleOpenChangeManager = () => {
    Modal.warn({
      title: "Atenção",
      content: (
        <>
          <Text>
            Voce está prestes a trocar o gerente da conta. Selecione abaixo o
            novo gerente.
          </Text>
          <Divider />

          <Form
            layout="horizontal"
            form={form}
            name="create-user"
            onFinish={onFinish}
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            initialValues={{ to_user: investor?.user_id }}
          >
            <Form.Item
              name="manager_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Select
                showSearch
                style={{
                  width: "100%"
                }}
                placeholder="Selecione Gerente"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={dataManagers?.map((user) => ({
                  label: user?.manager_name,
                  value: user?.manager_id
                }))}
              />
            </Form.Item>

            <Flex justify="end" gap={5}>
              <Form.Item>
                <Button
                  loading={sendindManager}
                  style={{ width: "100%", marginRight: 0 }}
                  type="primary"
                  htmlType="submit"
                >
                  Salvar
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: "100%", marginRight: 0 }}
                  type="default"
                  htmlType="reset"
                  onClick={() => Modal.destroyAll()}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </>
      ),
      footer: null
    });
  };

  return (
    <>
      {contextHolder}
      {loadingInvestor ? (
        <SkeletonLoading />
      ) : (
        <>
          <Title level={4}>Gerente da conta</Title>
          <Flex gap="small" wrap="wrap">
            <Button type="primary">
              <Link
                type="link"
                to={`/investidor/perfil/${manager?.id}/informacoes-pessoais`}
              >
                Visualizar gerente
              </Link>
            </Button>
            <Button
              type="dashed"
              onClick={handleOpenChangeManager}
              loading={sendindManager}
            >
              <GoArrowSwitch /> Trocar Gerente
            </Button>
          </Flex>
          <Divider />

          {fetchingManager || isFetching && <SkeletonLoading />}
          <Descriptions
            isLoading={loadingInvestor}
            column={1}
            bordered
            items={items}
            labelStyle={{ width: 150 }}
          />
        </>
      )}
    </>
  );
};

export default ManagerAccount;
