import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../services/api";

const getPromotions = async () => {
  try {
    const data = await api.get(`${process.env.REACT_APP_API}/v1/promotion`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        )}`
      }
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const postPromotion = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/promotion/create`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function usePromotionsRequest() {
  return useQuery(["promotions"], () => getPromotions(), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}

export function usePromotionPost(data) {
  return postPromotion(data);
}
