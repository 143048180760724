import { Avatar, Divider, Flex, List, Tag, Typography } from "antd";
import React from "react";
import { useManagerPortfolioRequest } from "../../Hooks/manager/useManager.query";
import { Link, useParams } from "react-router-dom";
import SkeletonLoading from "../../components/Loading/skeletonLoading";
import { formatCurrency } from "../../helpers/moneyFormat";

const { Text, Title } = Typography;

const Investors = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isFetched, isFetching } =
    useManagerPortfolioRequest(id);
  const portfolioData = data;

  console.log(data);

  return (
    <div>
      <Title level={4}>Clientes na carteira</Title>
      <Flex gap="middle" justify="center" align="center">
        <Tag style={{ width: "100%" }}>
          <Title level={5} style={{ textAlign: "center" }}>
            Total aportado em Real:{" "}
            {formatCurrency(data?.total?.realIncome, "BRL")}
          </Title>
        </Tag>
        <Tag style={{ width: "100%" }}>
          <Title level={5} style={{ textAlign: "center" }}>
            Total aportado em Dollar:{" "}
            {formatCurrency(data?.total?.dollarIncome, "USD")}
          </Title>
        </Tag>
      </Flex>
      <Divider />
      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <List
          dataSource={data?.clients}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Link
                  to={`/investidor/perfil/${item?.id}/informacoes-pessoais/editar`}
                  key="list-loadmore-edit"
                >
                  editar
                </Link>,
                <Link
                  to={`/investidor/perfil/${item?.id}/informacoes-pessoais`}
                  key="list-loadmore-more"
                >
                  visualizar
                </Link>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`https://api.dicebear.com/7.x/fun-emoji/svg?seed=${item?.name}`}
                  />
                }
                title={
                  <Link
                    to={`/investidor/perfil/${item?.id}/informacoes-pessoais`}
                  >
                    {item?.name}
                  </Link>
                }
                description={item?.name}
              />
              <div>
                <Tag>
                  Operando: {formatCurrency(item.amount, item.currency)}
                </Tag>
                <br />
                <Tag>Aporte: {formatCurrency(item.income, item.currency)}</Tag>
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default Investors;
