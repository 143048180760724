import React from "react";
import { ErrorNotFound } from "../../components/Illustrations";
import { Layout, Typography } from "antd";
import { Link } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Title } = Typography;

const NotFound = () => {
  return (
    <Layout>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: 700,
          flexDirection: "column",
          marginTop: 100,
          gap: 20
        }}
      >
        <Title style={{ marginBottom: 50 }} level={2}>
          Pagina não encontrada
        </Title>
        <ErrorNotFound />
        <Title style={{ marginBottom: 50 }} level={4}>
          <Link to={"/painel"}>
            Voltar para pagina inicial <ExportOutlined />
          </Link>
        </Title>
      </Content>
    </Layout>
  );
};

export default NotFound;
