const types = {
  1: "RENTABILIDADE",
  2: "APORTE",
  3: "Bonificaçoes",
  4: "Calculo novo investimento",
  5: "SAQUE",
  6: "Calculo após saque",
  7: "Calculo após bonificação",
  8: "REINVESTIMENTO"
};

export const typeFormat = (type) => {
  return types[type] || "";
};
