export default function backgroundColumnTable(data, text, colorNew, colorOld) {
  return text;
  // return {
  //   props: {
  //     style: {
  //       background: parseInt(data) === 1 ? colorNew : colorOld
  //     },

  //     className: "hover-column"
  //   },
  //   children: text
  // };
}
