import React, { useState } from "react";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Flex,
  Row,
  Statistic,
  Tag,
  Typography
} from "antd";
import { dateEndContract, daysToEndContract } from "../../helpers/dateHelper";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
const { Title } = Typography;

const PersonalInformations = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [hiddenDocument, setHiddenDocument] = useState(false);

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Title level={4}>Informaçoes Pessoal</Title>
        <Button onClick={() => navigate(`editar`)}>Editar</Button>
      </Flex>
      <Divider />
      <Col md={24}>
        <Flex justify="flex-start" align="center" wrap="wrap" gap={5}>
          {investor?.clientWallets?.map((wallets) => (
            <Tag key={wallets.id}>
              <Title level={5}>{wallets?.planWallet?.plan}</Title>{" "}
              <p>
                <small>Início:</small> {wallets?.initial_date}{" "}
                <small>Fim:</small> {wallets?.final_date}
              </p>
            </Tag>
          ))}
        </Flex>
      </Col>
      <Divider />
      <Descriptions column={1} bordered labelStyle={{ width: 180 }}>
        <Descriptions.Item label="login" span={3}>
          {hiddenDocument ? (
            <>
              <EyeInvisibleOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              {investor?.document}{" "}
            </>
          ) : (
            <>
              {" "}
              <EyeOutlined
                onClick={handleHiddenDocument}
                className="mr-5"
              />{" "}
              ***********{" "}
            </>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Nome">{investor?.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{investor?.email}</Descriptions.Item>
        <Descriptions.Item label="Telefone">
          {investor?.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Whatsapp">
          {investor?.whatsapp}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions column={1} bordered labelStyle={{ width: 180 }}>
        <Descriptions.Item label="Pix">{investor?.pix}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default PersonalInformations;
