import { Tag } from "antd";

const types = {
  1: "Cliente",
  50: "G.N",
  99: "ADMIN",
  80: "Escritorio",
  60: "Gerente Senior ",
  5: "Colaborador"
};
const typesTag = {
  1: "gold",
  50: "green",
  99: "magenta",
  80: "purple"
};

export const typeAccess = (type) => {
  return <Tag color={typesTag[type]}>{types[type]}</Tag> || "";
};
