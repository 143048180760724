import React from "react";
import { AccessDenied } from "../../components/Illustrations";
import { Layout, Typography } from "antd";

const { Content } = Layout;
const { Title } = Typography;

const NotAuthorized = () => {
  return (
    <Layout>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: 400,
          flexDirection: "column",
          marginTop: 100,
          gap: 20
        }}
      >
        <Title style={{ marginBottom: 50 }}>Acesso Negado</Title>
        <AccessDenied />
      </Content>
    </Layout>
  );
};

export default NotAuthorized;
