import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const postTransaction = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/transaction/create`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const withdralTotal = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/withdrawal/withdrawal-total`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const withdralBeyond = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/withdrawal/withdrawal-beyond-profitability`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const postMovimentation = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/transaction/movement`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getTransactionWalletId = async (walletId) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/transaction/transactions-wallet-id?wallet_id=${walletId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};

const postBachReinvest = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/transaction/batch-reinvestment`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useTransactionPostRequest(data) {
  return postTransaction(data);
}

export function useBachReinvestRequest(data) {
  return postBachReinvest(data);
}

export function useTransactionTotalWithdraw(data) {
  return withdralTotal(data);
}

export function useWithdrawBeyondPostRequest(data) {
  return withdralBeyond(data);
}

export function useMovimentsPost(data) {
  return postMovimentation(data);
}

export function useTransactionWalletId(walletId) {
  return useQuery(
    ["getTransactionWalletId"],
    () => getTransactionWalletId(walletId),
    {
      onSuccess: (data) => {
        return data;
      }
    }
  );
}
