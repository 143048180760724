export const formatCurrency = (amount = 0, currency = "USD") => {
  if (!amount) return "$ 0,00";

  const amounts = parseFloat(amount);
  const localeCurrency = currency === "USD" ? "USD" : "BRL";
  // Use a expressão regular para adicionar pontos de milhar e vírgulas ao número
  const formattedAmount = amounts.toLocaleString("en-US", {
    style: "currency",
    currency: localeCurrency
  });

  return formattedAmount;
};

export const mathDollarRtx = (dollar) => {
  const result = parseFloat(dollar) + 0.05;
  return Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL"
  }).format(result);
};

export const parseValueFloat = (value) => {
  return parseFloat(value.replace(/[\$,]/g, ""));
};


