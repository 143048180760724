import { Button, Flex, notification, Progress, Row } from "antd";
import { IKContext, IKUpload } from "imagekitio-react";
import React, { useRef, useState } from "react";
import Lottie from "react-lottie";
import animationDataError from "../Lotties/error";
import animationDataSuccess from "../Lotties/success";

const DefaultUpload = ({
  title,
  id,
  label,
  folder,
  controller,
  action,
  callback,
  ...props
}) => {
  const reftest = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const [progress, setProgress] = useState(0);
  const [startUpload, setStartUpload] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const publicKey = "public_Hqt+AgHm0gjcWTX7lFzrSP0QwhE=";
  const urlEndpoint = "https://ik.imagekit.io/qqrtx9mgqo/";
  const authenticationEndpoint = `${process.env.REACT_APP_API}/v1/site/auth`;

  const onError = (err) => {
    console.log("error: ", err);
    setUploadError(true);
    setStartUpload(false);
    api.error({
      message: "Error",
      description: err.message
    });
  };

  const onSuccess = (res) => {
    const imageUrl = res.url;
    const dataDocument = {
      imageUrl,
      id,
      controller,
      action,
      ...props
    };
    callback(dataDocument);
    api.success({
      message: "Sucesso",
      description: "Arquivo enviado com sucesso."
    });
    console.log("dataDocument", dataDocument);
    setStartUpload(false);
  };

  const onUploadStart = (evt) => {
    setStartUpload(true);
  };

  const onUploadProgress = (event) => {
    const percent = Math.floor((event.loaded / event.total) * 100);
    setProgress(percent);
    setUploadError(false);
    setUploadSuccess(false);
    if (percent === 100) {
      setProgress(0);
    }
  };
  return (
    <Row>
      {contextHolder}{" "}
      <div>
        <IKContext
          publicKey={publicKey}
          urlEndpoint={urlEndpoint}
          authenticationEndpoint={authenticationEndpoint}
        >
          <IKUpload
            fileName={`${title}`}
            onError={onError}
            onSuccess={onSuccess}
            useUniqueFileName={false}
            folder={folder}
            onUploadStart={onUploadStart}
            onUploadProgress={onUploadProgress}
            className="upload"
            style={{ display: "none" }}
            inputRef={reftest}
          />
        </IKContext>
        <Flex justifyContent="center" alignItems="center">
          {reftest && (
            <>
              <Button
                type="primary"
                size="large"
                loading={startUpload && !uploadSuccess}
                onClick={() => reftest.current.click()}
              >
                {label}
              </Button>
              {uploadSuccess && "sucesso"}
              {uploadError && "error"}
            </>
          )}
          {progress > 0 ? (
            <>
              <Progress
                style={{ marginTop: "20px" }}
                type="linear"
                percent={progress}
                size={20}
              />
            </>
          ) : (
            <></>
          )}
          {uploadSuccess && (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationDataSuccess,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
              height={60}
              width={60}
              style={{
                position: "relative",
                left: "75%",
                // transform: "translateX(-50%)",
                top: "35px"
              }}
            />
          )}
          {uploadError && (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationDataError,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice"
                }
              }}
              height={35}
              width={35}
              style={{
                position: "relative",
                left: "75%",
                // transform: "translateX(-50%)",
                top: "35px"
              }}
            />
          )}
        </Flex>
      </div>
    </Row>
  );
};

export default DefaultUpload;
