export default function itemsDescriptionsData(
  label,
  value,
  defaultMessage = "",
  validateFn = false
) {
  return {
    label: label,
    children: value ? value : defaultMessage,
    contentStyle: {
      background: value ? "" : "#ff4040" // Fundo vermelho se o valor não estiver presente
    },
    labelStyle: {
      background: value ? "" : "#ff4040" // Fundo vermelho se a validação falhar
    }
  };
}
