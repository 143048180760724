import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  notification
} from "antd";
import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  usePlanFetchRequest,
  useUpdatePlan
} from "../../Hooks/plans/usePlans.query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};

const PlanEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [minAmount, setMinAmount] = useState(0);
  const [form] = Form.useForm();

  const { data } = usePlanFetchRequest(id);
  const plan = data?.data;

  const { isLoading, mutate } = useMutation(useUpdatePlan, {
    onSuccess: (data) => {
      console.log(data);
      if (data.status !== 200) {
        api.error({
          message: "Erro",
          description: data.message
        });
        return;
      }
      api.success({
        message: "Sucesso",
        description: data.message
      });
      queryClient.invalidateQueries("planos");
      navigate("/planos");
    }
  });

  const onFinish = (inputForm) => {
    const data = {
      id,
      ...inputForm,
      gains_between: `${inputForm.start_gain} - ${inputForm.end_gain}`
    };

    mutate(data);
  };

  return (
    <div className="layout-content">
      {contextHolder}
      <Flex justify="space-between" align="center"></Flex>
      <Divider />
      <Row gutter={[24, 5]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={`Editar plano ${plan?.plan}`}
          >
            <Form
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
              {...layout}
              form={form}
              name="create-user"
              onFinish={onFinish}
              fields={[
                {
                  name: ["contract_time"],
                  value: plan?.contract_time
                },
                {
                  name: ["gains_between"],
                  value: plan?.gains_between
                },
                {
                  name: ["initial_value"],
                  value: plan?.initial_value
                },
                {
                  name: ["gains_manager"],
                  value: plan?.gains_manager
                },
                {
                  name: ["plan"],
                  value: plan?.plan
                },
                {
                  name: ["price"],
                  value: plan?.price
                },
                {
                  name: ["promotion"],
                  value: plan?.promotion
                },

                {
                  name: ["warranty"],
                  value: plan?.warranty
                }
                // {
                //   name: ["start_gain"],
                //   value: plan?.gains_between?.split("-")[0]
                // },
                // {
                //   name: ["end_gain"],
                //   value: plan?.gains_between?.split("-")[1]
                // }
              ]}
            >
              <Form.Item
                name="plan"
                label="Nome Plano"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Ganhos variantes de clientes">
                <Space align="start" direction="horizontal">
                  <Form.Item name="start_gain" label="de">
                    <InputNumber
                      style={{
                        width: "100%"
                      }}
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value?.replace("%", "")}
                      onBlur={(value) =>
                        setMinAmount(parseFloat(value.target.value))
                      }
                    />
                  </Form.Item>
                  <Form.Item name="end_gain" label="até">
                    <InputNumber
                      style={{
                        width: "100%"
                      }}
                      min={minAmount}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value?.replace("%", "")}
                    />
                  </Form.Item>
                </Space>
              </Form.Item>
              <Form.Item name="gains_manager" label="Ganho do G.N.">
                <InputNumber
                  style={{
                    width: "100%"
                  }}
                  min={0}
                  max={10}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "")}
                />
              </Form.Item>
              <Form.Item
                name="contract_time"
                label="Tempo de contrato em meses"
              >
                <InputNumber
                  min={1}
                  style={{
                    width: "100%"
                  }}
                />
              </Form.Item>
              <Form.Item name="initial_value" label="Valor inicial">
                <InputNumber
                  style={{
                    width: "100%"
                  }}
                  defaultValue={1000}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  loading={isLoading}
                  style={{ width: "100%", marginRight: 0 }}
                  type="primary"
                  htmlType="submit"
                >
                  SALVAR
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
  kkk;
};

export default PlanEdit;
