export const contractTime = (dateStr, monthsToAdd) => {
  if (!dateStr) return;
  if (!monthsToAdd) return;
  // Converter a string de data para um objeto Date
  const [day, month, year] = dateStr.split("/").map(Number);
  const date = new Date(year, month - 1, day);

  // Adicionar os meses
  const targetMonth = date.getMonth() + monthsToAdd;
  const targetYear = date.getFullYear() + Math.floor(targetMonth / 12);
  const finalMonth = targetMonth % 12;

  // Ajustar o ano e o mês
  const finalDate = new Date(targetYear, finalMonth, date.getDate());

  // Ajustar para o último dia do mês, se necessário
  if (finalDate.getDate() !== date.getDate()) {
    finalDate.setDate(0);
  }

  // Formatar a data de volta para o formato dd/mm/yyyy
  const formattedDay = String(finalDate.getDate()).padStart(2, "0");
  const formattedMonth = String(finalDate.getMonth() + 1).padStart(2, "0");
  const formattedYear = finalDate.getFullYear();

  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
};
