import React, { useState } from "react";
import { useWalletsReinvestRequest } from "../../Hooks/wallet/useWallet.query";
import {
  Button,
  Card,
  Drawer,
  Table,
  Typography,
  Form,
  DatePicker,
  Row,
  Col
} from "antd";
import { formatCurrency } from "../../helpers/moneyFormat";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useBachReinvestRequest } from "../../Hooks/transactions/useTransactions.query";

const { Text } = Typography;

const columns = [
  {
    title: "Conta",
    dataIndex: "account_number",
    key: "account_number",
    render: (text) => {
      return <Text strong>{text}</Text>;
    },
    width: 100
  },
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    render: (text, _) => {
      http: return (
        <Link to={`/investidor/perfil/${_.id}/informacoes-pessoais`}>
          {text}
        </Link>
      );
    }
  },
  {
    title: "Valor Operando",
    dataIndex: "amount",
    key: "amount",
    render: (text, _) => {
      return <p>{formatCurrency(text, _.currency)}</p>;
    },
    width: 160
  },
  {
    title: "Valor a ser reinvestido",
    dataIndex: "available_for_withdrawal",
    key: "available_for_withdrawal",
    render: (text, _) => {
      return <p>{formatCurrency(text, _.currency)}</p>;
    },
    width: 160
  }
];

const Reinvestment = () => {
  const [form] = Form.useForm();
  const [openReinvestDrawer, setOpenReinvestDrawer] = useState(false);
  const [dateMovement, setDateMovement] = useState("");
  const [amount, setAmount] = useState({
    DOLLAR: 0,
    REAL: 0
  });

  const { data } = useWalletsReinvestRequest();

  const { mutateAsync, isLoading } = useMutation(useBachReinvestRequest, {
    onSuccess: () => {
      api.success({
        message: "Sucesso",
        description: data.data.message
      });
    }
  });

  const onCloseDrawerBatchReinvest = () => {
    setOpenReinvestDrawer(false);
  };

  const handleClickOpenDrawerBatchReinvest = () => {
    setOpenReinvestDrawer(true);
  };

  const changeDate = (date, dateString) => {
    setDateMovement(dateString);
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      date: dateMovement
    };
    mutateAsync(data);
  };

  return (
    <div className="layout-content">
      <Button
        onClick={handleClickOpenDrawerBatchReinvest}
        type="primary"
        block
        style={{ marginBottom: 20 }}
      >
        Lançar
      </Button>
      <br />
      <Table
        tableLayout="fixed"
        className="custom-row-hover"
        columns={columns}
        size="middle"
        dataSource={data}
      />
      <Drawer
        width={500}
        title="Reinvestir valores nas contas"
        onClose={onCloseDrawerBatchReinvest}
        open={openReinvestDrawer}
      >
        <Form
          preserve={false}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Row wrap={false}>
            <Col flex="auto">
              <Form.Item name="date">
                <DatePicker format={"DD-MM-YYYY"} onChange={changeDate} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              loading={!!isLoading}
              style={{ width: "100%", marginRight: 0 }}
              type="primary"
              htmlType="submit"
            >
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default Reinvestment;
