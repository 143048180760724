import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOfficeFetchRequest } from "../../Hooks/offices/offices.query";
import { Content } from "antd/es/layout/layout";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  notification
} from "antd";
import { useQueryClient } from "@tanstack/react-query";

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 }
};

const ViewOffice = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const { data } = useOfficeFetchRequest(id);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const office = data?.data;

  useEffect(() => {
    if (office?.user_id === null) {
      Modal.warning({
        title: "Alguns dados requerem sua atenção",
        content: (
          <>
            O escritorio <b>{office?.name}</b> não possui um usuário cadastrado.
            Por favor, cadastre um usuário para este escritório. <br /> <br />{" "}
            <b>Obs:</b> O usuário cadastrado é necessario para realizar o LOGIN
            dentro do painel de escritório.
            <br />
            {office?.email ? (
              <p>
                Email cadastrado, verifique e confirme para enviar as instruções
                para o escritorio
              </p>
            ) : (
              <p>
                Email nao cadastrado, por favor digite abaixo o email para o
                escritório receber as instruções de acesso ao painel.
              </p>
            )}
            <Form
              form={form}
              fields={[
                {
                  name: ["email"],
                  value: office?.email
                }
              ]}
              onFinish={updateUserToOffice}
              style={{ marginTop: 24 }}
            >
              <Form.Item name="email" label="Email">
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Flex gap={5}>
                  <Button
                    // loading={!!isLoading}
                    style={{ width: "100%", marginRight: 0 }}
                    type="primary"
                    htmlType="submit"
                  >
                    Enviar
                  </Button>
                  <Button onClick={() => Modal.destroyAll()}>
                    Deixar para depois
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
            {office.email}
          </>
        ),
        footer: null
      });
    }
  }, [office]);

  const updateUserToOffice = (values) => {
    console.log(values);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="layout-content">
      <Content className="p-0">
        <Row gutter={[24, 5]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24 px-10"
              title={`Escritorio: ${office?.name}`}
            >
              <Row className="mx-24 my-25 text-center px-10">
                <Descriptions column={1}>
                  <Descriptions.Item label="Username">
                    {office?.username}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {office?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Nome">
                    {office?.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Telefone">
                    {office?.phone}
                  </Descriptions.Item>
                  <Descriptions.Item label="Whatsapp">
                    {office?.whatsapp}
                  </Descriptions.Item>
                  <Descriptions.Item label="Endereço">
                    {office?.street}
                  </Descriptions.Item>
                  <Descriptions.Item label="Cidade">
                    {office?.city} - {office?.state}
                  </Descriptions.Item>
                  <Descriptions.Item label="bairro">
                    {office?.neighborhood}
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default ViewOffice;
