import React, { useState } from "react";
import {
  Flex,
  Divider,
  Card,
  Table,
  Modal,
  Button,
  Form,
  Input,
  notification,
  Row,
  Col,
  Menu
} from "antd";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  useContractRequest,
  useContractUpdate,
  useSendContractClient
} from "../../Hooks/contracts/useContracts.query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileOutlined, EditFilled } from "@ant-design/icons";
import { validateEmail } from "../../helpers/validateInputsPersonal";
import { checkIsEmpty } from "../../helpers/checkIsEmpty";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const ViewContract = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openConfirmSendContract, setOpenConfirmSendContract] = useState(false);
  const [openConfirmSendContractError, setOpenConfirmSendContractError] =
    useState(false);
  const { data: contract, isLoading: loadingContract } = useContractRequest(id);

  const handleOpenEdit = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { isLoading, mutateAsync } = useMutation(useContractUpdate, {
    onSuccess: (data) => {
      if (data.status === 400) {
        api.error({
          message: "Error",
          description: data.message
        });
        return;
      }
      form.resetFields();
      queryClient.invalidateQueries("contract");
      api.success({
        message: "Sucesso",
        description: "Dados Salvo com sucesso"
      });
      setIsModalOpen(false);
      // navigate(`/investidor/perfil/${id}`);
    },
    onError: (err) => {
      api.error({
        message: "Error",
        description: err.error
      });
    }
  });

  const { mutateAsync: sendContract, isLoading: sendingContract } = useMutation(
    useSendContractClient,
    {
      onSuccess: (data) => {
        if (data.status === 500) {
          api.error({
            message: "Error",
            description: data.message
          });
          return;
        }
        api.success({
          message: "Sucesso",
          description: "Dados Salvo com sucesso"
        });
      },
      onError: (err) => {
        console.error(":", err);
        api.error({
          message: "Error",
          description: err.error
        });
      }
    }
  );

  const onFinish = (values) => {
    mutateAsync({ id, values });
  };

  const handleSendContract = () => {
    if (
      contract?.amount_contract === null ||
      contract?.amount_contract === ""
    ) {
      api.error({
        message: "Error",
        description: "Verifique o Valor de aporte"
      });
      return;
    }
    setOpenConfirmSendContract(true);
  };

  const handleConfirmSendContract = () => {
    sendContract(id);
  };

  const handleClickMenu = (e) => {
    console.log("click", e);
    navigate(e.key);
  };

  if (loadingContract) {
    return "carregando....";
  }

  const extractFields = (data, prefix = "") => {
    return Object.entries(data).flatMap(([key, value]) => {
      const fieldName = prefix ? `${prefix}.${key}` : key; // Nome do campo com prefixo para campos aninhados
      if (value && typeof value === "object" && !Array.isArray(value)) {
        return extractFields(value, fieldName); // Recursão para objetos internos
      }
      return {
        name: fieldName,
        value: value ?? "" // Se o valor for null ou undefined, define como string vazia
      };
    });
  };
  const fields = extractFields(contract);

  return (
    <>
      {contextHolder}

      <Modal
        title="Enviar contrato para assinatura"
        open={openConfirmSendContract}
        onCancel={() => setOpenConfirmSendContract(false)}
        footer={false}
        destroyOnClose={true}
        maskClosable={true}
      >
        {validateEmail(contract?.email) ? (
          <>
            <p>
              O contrato para <strong>{contract?.name}</strong> está pronto.
              Clique no botão abaixo para enviar o contrato para o e-mail:{" "}
              <strong>{contract?.email}</strong>
            </p>
            <Button
              type="primary"
              onClick={handleConfirmSendContract}
              // loading={sendingContract}
              isLoading={sendingContract}
            >
              Enviar Contrato
            </Button>
          </>
        ) : (
          <p>
            Não foi possível enviar o contrato para{" "}
            <strong>{contract?.name}</strong> porque o e-mail fornecido não é
            válido. Por favor, atualize o endereço de e-mail antes de prosseguir
            com o envio do contrato.
          </p>
        )}
      </Modal>

      <Row gutter={8}>
        <Col md={6} sm={24} className="no-print">
          <Row>
            <Card
              style={{
                width: "100%"
              }}
            >
              <Menu
                onClick={handleClickMenu}
                style={{ marginTop: 15 }}
                items={[
                  getItem("Detalhes", `detalhes`, <ProfileOutlined />),
                  getItem(
                    "Visualizar contrato",
                    "contrato",
                    <ProfileOutlined />
                  )
                ]}
              />
              <Menu
                onClick={handleOpenEdit}
                items={[
                  {
                    label: "Editar / Completar",
                    key: "mail",
                    icon: <EditFilled />
                  }
                ]}
              />
              <Menu
                onClick={handleSendContract}
                items={[
                  {
                    label: "Enviar",
                    key: "mail",
                    icon: <ProfileOutlined />
                  }
                ]}
              />
            </Card>
          </Row>
        </Col>
        <Col md={16} sm={24}>
          <Flex
            justify="center"
            align="center"
            style={{ margin: "10px" }}
            gap={8}
            className="no-print"
          >
            {/* <Button onClick={handleOpenEdit}>Editar</Button> */}
            {/* <Button onClick={handleSendContract}>Enviar</Button> */}
          </Flex>
          <Row>
            <Outlet />
          </Row>
        </Col>
      </Row>

      <Modal
        title="Editar Dados Contrato"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        destroyOnClose={true}
        maskClosable={true}
      >
        <Form
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
          layout="vertical"
          form={form}
          name="create-user"
          onFinish={onFinish}
          fields={[
            {
              name: ["city"],
              value: contract.city
            },
            {
              name: ["amount_contract"],
              value: contract.amount_contract
            },
            {
              name: ["email"],
              value: contract.email
            },
            {
              name: ["expedition_rg"],
              value: contract.expedition_rg
            },
            {
              name: ["marital_status"],
              value: contract.marital_status
            },
            {
              name: ["name"],
              value: contract.name
            },
            {
              name: ["nationality"],
              value: contract.nationality
            },
            {
              name: ["neighborhood"],
              value: contract.neighborhood
            },
            {
              name: ["number"],
              value: contract.number
            },
            {
              name: ["number_cnh"],
              value: contract.number_cnh
            },
            {
              name: ["number_cpf"],
              value: contract.number_cpf
            },
            {
              name: ["number_rg"],
              value: contract.number_rg
            },
            {
              name: ["observations"],
              value: contract.observations
            },
            {
              name: ["phone"],
              value: contract.phone
            },
            {
              name: ["plan"],
              value: contract.plan
            },
            {
              name: ["postal_code"],
              value: contract.postal_code
            },
            {
              name: ["profession"],
              value: contract.profession
            },
            {
              name: ["state"],
              value: contract.state
            },
            {
              name: ["street"],
              value: contract.street
            },
            {
              name: ["whatsapp"],
              value: contract.whatsapp
            }
          ]}
        >
          <p>Informações Pessoais</p>
          <Form.Item key="name" name="name" label="Nome">
            <Input placeholder="Digite o nome completo" />
          </Form.Item>

          <Form.Item key="email" name="email" label="E-mail">
            <Input placeholder="Digite o seu e-mail" />
          </Form.Item>

          <Form.Item key="phone" name="phone" label="Telefone">
            <Input placeholder="Digite o seu telefone" />
          </Form.Item>

          <Form.Item key="whatsapp" name="whatsapp" label="WhatsApp">
            <Input placeholder="Digite o seu número de WhatsApp" />
          </Form.Item>

          <Form.Item
            key="marital_status"
            name="marital_status"
            label="Estado Civil"
          >
            <Input placeholder="Digite o seu estado civil" />
          </Form.Item>

          <Form.Item key="nationality" name="nationality" label="Nacionalidade">
            <Input placeholder="Digite sua nacionalidade" />
          </Form.Item>

          <Form.Item key="profession" name="profession" label="Profissão">
            <Input placeholder="Digite a sua profissão" />
          </Form.Item>

          <p>Endereço</p>
          <Form.Item key="street" name="street" label="Rua">
            <Input placeholder="Digite o nome da rua" />
          </Form.Item>

          <Form.Item key="number" name="number" label="Número">
            <Input placeholder="Digite o número da casa/apartamento" />
          </Form.Item>

          <Form.Item key="neighborhood" name="neighborhood" label="Bairro">
            <Input placeholder="Digite o nome do bairro" />
          </Form.Item>

          <Form.Item key="postal_code" name="postal_code" label="CEP">
            <Input placeholder="Digite o código postal (CEP)" />
          </Form.Item>

          <Form.Item key="state" name="state" label="Estado">
            <Input placeholder="Digite o estado" />
          </Form.Item>

          <Form.Item key="city" name="city" label="Cidade">
            <Input placeholder="Digite o nome da cidade" />
          </Form.Item>

          <p>Informações de Contrato</p>
          <Form.Item name="amount_contract" label="Valor do Contrato">
            <Input placeholder="Digite o valor do contrato" />
          </Form.Item>

          <Form.Item key="plan" name="plan" label="Plano">
            <Input placeholder="Digite o plano do contrato" disabled />
          </Form.Item>

          <p>Documentos e Identificação</p>
          <Form.Item key="number_cnh" name="number_cnh" label="Número da CNH">
            <Input placeholder="Digite o número da sua CNH" />
          </Form.Item>

          <Form.Item key="number_cpf" name="number_cpf" label="Número do CPF">
            <Input placeholder="Digite o número do seu CPF" />
          </Form.Item>

          <Form.Item key="number_rg" name="number_rg" label="Número do RG">
            <Input placeholder="Digite o número do seu RG" />
          </Form.Item>
          <Form.Item
            key="expedition_rg"
            name="expedition_rg"
            label="Orgão do RG"
          >
            <Input placeholder="Digite o orgão emissor do RG" />
          </Form.Item>

          <p>Observações</p>
          <Form.Item key="observations" name="observations" label="Observações">
            <Input placeholder="Digite qualquer observação relevante" />
          </Form.Item>

          <Form.Item>
            <Button
              loading={!!isLoading}
              style={{ width: "100%", marginRight: 0 }}
              type="primary"
              htmlType="submit"
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ViewContract;
